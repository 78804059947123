//
// Horizontal dividers
// --------------------------------------------------

@mixin nav-divider($color: $nav-divider-color, $margin-y: $nav-divider-margin-y, $ignore-warning: false) {
    height: 0;
    margin: $margin-y 0;
    overflow: hidden;
    border-top: 1px solid $color;
}
