//
// Content
// --------------------------------------------------

@mixin content-layout($x, $y, $overflow: visible) {
    width: 100%;
    margin: 0 auto;
    padding: $y $x 1px;
    overflow-x: $overflow;

    > .pull-t,
    > .pull-y {
        margin-top: -$y;
    }

    > .pull-b,
    > .pull-y {
        margin-bottom: -1px;
    }

    > .pull-r,
    > .pull-x {
        margin-right: -$x;
    }

    > .pull-r,
    > .pull-x {
        margin-left: -$x;
    }

    > .pull {
        margin: (-$y) (-$x) (-1px);
    }

    @at-root {
        #{&}#{&}-full {
            padding-bottom: $y;

            > .pull-b,
            > .pull-y,
            > .pull {
                margin-bottom: -$y;
            }
        }
    }

    p,
    .push,
    .block,
    .items-push > div {
        margin-bottom: $y;
    }

    .items-push-2x > div {
        margin-bottom: $y*2;
    }

    .items-push-3x > div {
        margin-bottom: $y*3;
    }
}