
@import "../../../assets/scss/custom/errors";

.tabulatorDropdown {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;

	.selectField {
		border: 0;
		flex: 1 1 300px;
		height: 100%;
		line-height: 1.5;
		padding: 5px 10px 5px 5px;

		&:focus {
			outline: none;
		}
	}
}
