
// Custom router view transition
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}
