

// style for the vee-validate custom error

.control {
	width: 100%;

	span {
		display: block;
	}

	input {
		padding: 5px 10px;
	}

	&.invalid {
		input {
			border: 1px #eb0600 solid;
		}
	}

	&.valid {
		input {
			border: 1px #045929 solid;
		}
	}
}
.control.invalid input,
.control.invalid span {
	color: #eb0600;
}
.control.valid input,
.control.valid span {
	color: #045929;
}