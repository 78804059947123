//
// Display
// --------------------------------------------------

.overflow-y-auto {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.opacity-0 { opacity: 0; }
.opacity-25 { opacity: .25; }
.opacity-50 { opacity: .50; }
.opacity-75 { opacity: .75; }
.opacity-1 { opacity: 1; }
