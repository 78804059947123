<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="3D Model properties"
					header-bg
				>
					<div class="d-block text-center">
						<b-form-group
							class="form-group"
							label="Name: "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<div
								class="control"
							>
								<b-form-input
									v-model="singleModel3d.name"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							label="GLB file"
							label-cols-lg="4"
						>
							<b-form-file
								id="glb"
								ref="glb"
								:placeholder="placeHolders.glb"
								@click.native.prevent="updateGlbPreview"
							/>
							<div>
								<b-alert
									v-if="glb"
									show
									dismissible
								>
									{{ glb }}
								</b-alert>
							</div>
						</b-form-group>

						<b-form-group
							label="HDR texture file"
							label-cols-lg="4"
						>
							<b-form-file
								id="hdr"
								ref="hdr"
								:placeholder="placeHolders.hdr"
								@click.native.prevent="updateHdrPreview"
							/>
							<div>
								<b-alert
									v-if="hdr"
									show
									dismissible
								>
									{{ hdr }}
								</b-alert>
							</div>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Environmental intensity "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Environmental Intensity"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.envIntensity"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Camera Alpha"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Camera Alpha"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.cameraAlpha"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							label="Automatic rotation:"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<b-form-checkbox
								class="cameraCheckbox"
								switch
								size="lg"
								:checked="singleModel3d.cameraAutoRotate === 1 ? true : false"
								@change="singleModel3d.cameraAutoRotate = $event"
							/>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Camera Beta"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Camera Beta"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.cameraBeta"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Camera Radius"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Camera Radius"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.cameraRadius"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							label="Camera Rotation Speed"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<ValidationProvider
									v-slot="{ classes, errors }"
									rules="double"
									name="Camera Rotation Speed"
								>
									<div
										class="control"
										:class="classes"
									>
										<b-form-input
											v-model.number="singleModel3d.cameraRotationSpeed"
											type="text"
										/>
										<span>{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<b-form-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="singleModel3d.cameraRotationSpeed == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleModel3d.cameraRotationSpeed = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Camera Zoom Limit Down"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Camera Zoom Limit Down"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.cameraZoomLimitDown"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Camera Zoom Limit Up"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<ValidationProvider
								v-slot="{ classes, errors }"
								rules="double"
								name="Camera Zoom Limit Up"
							>
								<div
									class="control"
									:class="classes"
								>
									<b-form-input
										v-model.number="singleModel3d.cameraZoomLimitUp"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<b-form-group
							label="Print Material Name"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<ValidationProvider
									v-slot="{ classes, errors }"
									rules="alpha"
									name="Print Material Name"
								>
									<div
										class="control"
										:class="classes"
									>
										<b-form-input
											v-model="singleModel3d.printMaterialName"
											type="text"
										/>
										<span>{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<b-form-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="singleModel3d.printMaterialName == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleModel3d.printMaterialName = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							label="Texture Material Name"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singleModel3d.textureMaterialName"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<b-form-checkbox
											id="checkbox-textureMaterialName"
											name="checkbox-textureMaterialName"
											:checked="singleModel3d.textureMaterialName == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleModel3d.textureMaterialName = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							label="Transparency Mode"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singleModel3d.transparencyMode"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<b-form-checkbox
											id="checkbox-transparencyMode"
											name="checkbox-transparencyMode"
											:checked="singleModel3d.transparencyMode == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleModel3d.transparencyMode = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							label="Texture Url"
							label-cols-lg="4"
						>
							<b-form-file
								id="texture"
								ref="texture"
								:placeholder="placeHolders.texture"
								@click.native.prevent="updateTexturePreview"
							/>
							<div>
								<b-alert
									v-if="texture"
									show
									dismissible
								>
									{{ texture }}
								</b-alert>
							</div>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<script lang="ts" src="./EditModel3dTable.ts"></script>

<style lang="scss">
@import "../../assets/scss/custom/errors";
</style>

<style scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
