//
// Grid
// --------------------------------------------------

// Tiny grid gutters
.row.gutters-tiny {
    margin-right: (-($space-row-gutter-tiny / 2));
    margin-left: (-($space-row-gutter-tiny / 2));

    > .col,
    > [class*="col-"] {
        padding-left: ($space-row-gutter-tiny / 2);
        padding-right: ($space-row-gutter-tiny / 2);
    }

    .push,
    .block,
    &.items-push > div {
        margin-bottom: $space-row-gutter-tiny;
    }
}

// Responsive grid gutters
@include media-breakpoint-down(sm) {
    .row:not(.gutters-tiny):not(.no-gutters) {
        margin-right: (-($space-mobile / 2));
        margin-left: (-($space-mobile / 2));

        > .col,
        > [class*="col-"] {
            padding-left: ($space-mobile / 2);
            padding-right: ($space-mobile / 2);
        }
    }
}

// Equal height of elements inside columns
.row.row-deck > div {
    display: flex;
    align-items: stretch;

    > .block {
        min-width: 100%;
    }
}
