<template>
	<div>
		<div class="">
			<div class="back-arrow pl-6 pt-2">
				<router-link to="/application">
					<i class="fa fa-fw fa-arrow-left" />
					<span> Back to API Permissions </span>
				</router-link>
			</div>
			<base-page-heading
				title="Application edit"
				subtitle=""
				class="d-print-none"
			>
				<template #extra>
					<b-button
						ref="showBtn"
						type="submit"
						size="md"
						variant="secondary"
						@click="showModal"
					>
						<i class="fa fa-fw fa-plus" /> 	Create new
					</b-button>
				</template>
			</base-page-heading>

			<div class="row content">
				<EditApiPermissionTable
					:route-id="routeId"
				/>
			</div>
		</div>
	</div>
</template>
<style scoped>
.back-arrow{
	background-color: #f6f7f8;
}
</style>
