.tip {
	text-align: center;
	background-color: #29b6f6;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	font-size: 12px;
	line-height: 26px;
	cursor: default;
	transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
	&:hover {
		cursor: pointer;
		background-color: #ccc;
	}
	&::before {
		content: '?';
		font-weight: 700;
		color: #fff;
	}
  }
  