<template>
	<div class="">
		<div class="back-arrow pl-6 pt-2">
			<router-link to="/template">
				<i class="fa fa-fw fa-arrow-left" />
				<span> Back to templates </span>
			</router-link>
		</div>
		<base-page-heading
			title="Template Edit"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="btnShow"
					type="submit"
					size="lg"
					variant="secondary"
					@click="updateTemplateProperties"
				>
					Save changes
				</b-button>
			</template>
		</base-page-heading>

		<div class="row content">
			<EditTemplateTable
				ref="edittemplate"
				:template-id="templateId"
				@routeTemplateEdit="routeTemplateEdit"
			/>
		</div>
	</div>
</template>

<style scoped>
.back-arrow{
	background-color: #f6f7f8;
}
</style>
