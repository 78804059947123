//
// OneUI variables
// --------------------------------------------------


// Colors

$theme-colors: map-merge((
    "primary-light":        lighten($primary, 15%),
    "primary-lighter":      lighten($primary, 28%),
    "primary-dark":         #4B5563,
    "primary-darker":       #1F2937,
    "success-light":        lighten($success, 36%),
    "info-light":           lighten($info, 30%),
    "warning-light":        lighten($warning, 30%),
    "danger-light":         lighten($danger, 30%),
), $theme-colors);

$body-bg-dark:              darken($body-bg, 4.5%);
$body-bg-light:             lighten($body-bg, 3%);

$body-color-dark:           darken($body-color, 4%) !default;
$body-color-light:          #e9ecef !default;

// Paragraphs

$paragraph-line-height:     1.6;
$paragraph-line-height-lg:  1.8;


// Fonts

$font-size-root:                    16px;
$enable-text-optimize-legibility:   true; // you can set it to false to increase rendering performance


// Z-index master list additions

$zindex-block-pinned:       $zindex-fixed + 1 !default;
$zindex-sidebar:            $zindex-fixed + 2 !default;
$zindex-page-overlay:       $zindex-fixed + 3 !default;
$zindex-side-overlay:       $zindex-fixed + 4 !default;
$zindex-block-fullscreen:   $zindex-fixed + 5 !default;


// Buttons

$btn-alt-primary-bg:        lighten(theme-color("primary-lighter"), 5%);


// Header

$header-height:             4rem !default;           // Best values > 3rem and < 5rem
$header-bg:                 $white !default;
$header-dark-bg:            theme-color("primary-darker") !default;


// Sidebar and Side Overlay

$sidebar-bg:                $white !default;
$sidebar-dark-bg:           theme-color("primary-darker") !default;
$sidebar-width:             240px !default;
$sidebar-mini-width:        60px !default;

$side-overlay-bg:           $white !default;
$side-overlay-width:        320px !default;

$side-transition:           .28s ease-out !default;


// Main Navigation

$main-nav-transition:       .25s ease-out !default;


// Layout Spaces

$space-base:                1.875rem !default;                  // Content padding
$space-mobile:              1rem !default;                      // Content padding in mobile
$space-side:                1.25rem !default;                   // Content padding inside side content
$space-row-gutter-tiny:     .375rem !default;                   // Row with tiny gutter
$space-block:               1.25rem !default;                   // Block padding
$space-boxed:               1280px !default;                    // Boxed content max width
$space-narrow:              92% !default;                       // Narrow content max width
$space-main-max-width:      1920px !default;                    // Main content max width


// Third Party

$fa-font-path:                "~@fortawesome/fontawesome-free/webfonts" !default;
