//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and OneUI functions/mixins/styles etc
// --------------------------------------------------

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';
.tabulator-cell.selected-top {
    border-top: solid blue;
}

.tabulator-cell.selected-bottom {
    border-bottom: solid blue;
}

.tabulator-cell.selected-left {
    border-left: solid blue;
}

.tabulator-cell.selected-right {
    border-right: solid blue !important;
}

.tabulator-cell.autofill-top {
    border-top: dashed blue;
}

.tabulator-cell.autofill-bottom {
    border-bottom: dashed blue;
}

.tabulator-cell.autofill-left {
    border-left: dashed blue;
}

.tabulator-cell.autofill-right {
    border-right: dashed blue !important;
    ;
}

.tabulator-cell.selected-right.selected-bottom .priceInput .autofill {
    display: block;
}