
@import "../../../assets/scss/custom/errors";

.priceInput {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;

	.inputField {
		border: 0;
		flex: 1 1 300px;
		height: 100%;
		line-height: 1.5;
		padding: 5px 10px 5px 5px;

		&:focus {
			outline: none;
		}
	}
	.same-as-catalogue {
		color: #E9ECEF;
	}
	.currency {
		border-right: 0;
		padding: 5px 5px 5px 10px;
		line-height: 1.5;
		color: #999;
	}

	.autofill {
		color: blue;
		position: absolute !important;
		right: -5px;
		bottom: -5px;
		font-size: 10px;
		display: none;
	}

	.autofill:hover {
		cursor: crosshair;
	}
}
