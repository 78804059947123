//
// Page Loader
// --------------------------------------------------

#page-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 999998;
    transform: translateY(-100%);

    &.show {
        transform: translateY(0);
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin-top: -30px;
        margin-left: -30px;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: theme-color("primary");
        content: '';
        z-index: 999999;
        animation: page-loader .9s infinite ease-in-out;
    }
}


@keyframes page-loader {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}
