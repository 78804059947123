<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="bv-modal"
			ref="image-modal"
			hide-footer
		>
			<template #modal-title>
				Create New PDP Image
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					label="Language"
					label-for="example-select"
					label-cols-lg="3"
				>
					<b-input-group>
						<b-form-select
							id="example-select"
							v-model="langid"
							plain
						>
							<b-form-select-option :value="null">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(lang,id) in language "
								:key="id"
								:value="lang.id"
							>
								{{ lang.id }} | {{ lang.name }}
							</b-form-select-option>
						</b-form-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<b-form-checkbox
									id="checkbox-2"
									name="checkbox-1"
									:checked="langid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> langid = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Image"
					label-cols-lg="3"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="image"
						name="Image"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-file
								id="image"
								ref="image"
								:placeholder="placeHolders.thumbnailPlaceholder"
								@click.native.prevent="updateImagePreview"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>

					<div
						v-if="imagePreview"
						class="thumbnail"
					>
						<img
							:src="imagePreview"
							alt=""
							srcset=""
							height="100"
							width="100"
						>
						<div
							style="cursor:pointer"
							@click="removeImage"
						>
							&#10006; Remove
						</div>
					</div>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="width "
					label-for="block-form1-name"
					label-cols-lg="3"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="integer"
						name="Width"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-input
								ref="pdpImageName"
								v-model="width"
								type="text"
								:disabled="width === null ? true : false"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="height: "
					label-for="block-form1-name"
					label-cols-lg="3"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="integer"
						name="Height"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-input
								ref="pdpImageName"
								v-model="height"
								type="text"
								:disabled="height === null ? true : false"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="addPdpImage"
				>
					Save
				</b-button>
			</div>
		</b-modal>

		<b-modal
			id="bv-edit-modal"
			ref="image-edit-modal"
			hide-footer
		>
			<template #modal-title>
				Edit PDP Image
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					label="Language"
					label-for="example-select"
					label-cols-lg="5"
				>
					<b-input-group>
						<b-form-select
							id="example-select"
							v-model="editModalItems.languageid"
							plain
						>
							<b-form-select-option
								v-for="(lang,id) in language "
								:key="id"
								:value="lang.id"
							>
								{{ lang.id }} | {{ lang.name }}
							</b-form-select-option>
						</b-form-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<b-form-checkbox
									id="checkbox-2"
									name="checkbox-1"
									:checked="editModalItems.languageid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> editModalItems.languageid = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Image"
					label-cols-lg="5"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="integer"
						name="Height"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-file
								id="editImage"
								ref="editImage"
								:placeholder="placeHolders.editImagePlaceholder"
								@click.native.prevent="editImagePreview"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>

					<div
						v-if="editModalItems.url"
						class="thumbnail"
					>
						<img
							:src="editModalItems.url"
							alt=""
							srcset=""
							height="100"
							width="100"
						>
						<div
							style="cursor:pointer"
							@click="removeEditImage"
						>
							&#10006; Remove
						</div>
					</div>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="width "
					label-for="block-form1-name"
					label-cols-lg="5"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="integer"
						name="width"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-input
								ref="pdpImageName"
								v-model="editModalItems.width"
								type="text"
								:disabled="editModalItems.width === null ? true : false"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="height: "
					label-for="block-form1-name"
					label-cols-lg="5"
				>
					<ValidationProvider
						v-slot="{ classes, errors }"
						rules="integer"
						name="Height"
					>
						<div
							class="control"
							:class="classes"
						>
							<b-form-input
								ref="pdpImageName"
								v-model="editModalItems.height"
								type="text"
								:disabled="editModalItems.height === null ? true : false"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</ValidationProvider>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="editPdpImage()"
				>
					Save
				</b-button>
			</div>
		</b-modal>
		<b-row
			style="width: 95%"
			class="ml-5"
		>
			<b-col
				md="5"
				lg="5"
				sm="12"
			>
				<base-block
					title="PDP properties"
					header-bg
				>
					<div
						v-if="loaded"
						class="text-center"
					>
						<div
							class="spinner-grow text-primary m-5"
							role="status"
						>
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div
						v-else
						class="d-block text-center"
					>
						<b-form-group
							class="form-group"
							label="Name: "
							label-for="block-form1-name"
							label-cols-lg="3"
						>
							<div
								class="control"
							>
								<b-form-input
									ref="pdpImageName"
									v-model="singlePdp.name"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							label="Add offering Image:"
							label-class="font-w600"
							label-cols-lg="5"
						>
							<b-form-checkbox
								ref="pdpImageOfferingImage"
								switch
								size="lg"
								:checked="singlePdp.addOfferingImage === 1 ? true : false"
								@change="singlePdp.addOfferingImage = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>

			<b-col
				md="7"
				lg="7"
				sm="12"
			>
				<base-block
					title="PDP Images"
					header-bg
				>
					<b-row
						class="d-flex justify-content-end"
					>
						<b-col
							md="6"
							class="d-flex justify-content-end mb-2"
						>
							<router-link
								to="#"
								@click.native="showImageModal"
							>
								Add Image
							</router-link>
						</b-col>
					</b-row>
					<div
						ref="pdpImage"
					/>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<script lang="ts" src="./EditPdpTable.ts"></script>

<style scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
</style>

<style lang="scss">
@import "../../assets/scss/custom/errors";
</style>
