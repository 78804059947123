<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="category-modal"
			ref="category-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Category
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="addCategoryData.name"
						type="text"
					/>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Product Group"
					label-for="block-form-offering"
				>
					<b-input-group>
						<b-form-select
							id="block-form-offering"
							v-model="addCategoryData.offering"
						>
							<b-form-select-option :value="{}">
								--select offering--
							</b-form-select-option>
							<b-form-select-option
								v-for="(off,id) in offering "
								:key="id"
								:value="off"
							>
								{{ off.id }} - {{ off.name }} - {{ off.description }}
							</b-form-select-option>
						</b-form-select>
					</b-input-group>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Language"
					label-for="block-form-language"
				>
					<b-input-group>
						<b-form-select
							id="block-form-language"
							v-model="addCategoryData.languageid"
						>
							<b-form-select-option :value="null">
								--All--
							</b-form-select-option>
							<b-form-select-option
								v-for="(lang,id) in language "
								:key="id"
								:value="lang.id"
							>
								{{ lang.name }}
							</b-form-select-option>
						</b-form-select>
					</b-input-group>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addCategory()"
			>
				Save
			</b-button>
		</b-modal>
		<b-modal
			id="import-modal"
			ref="import-modal"
			hide-footer
		>
			<template #modal-title>
				Import Category
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Product Group"
					label-for="block-form-offering"
				>
					<b-input-group>
						<b-form-select
							id="block-form-offering"
							v-model="importCatalogData.offering"
						>
							<b-form-select-option :value="{}">
								--select offering--
							</b-form-select-option>
							<b-form-select-option
								v-for="(off,id) in offering"
								:key="id"
								:value="off"
							>
								{{ off.id }} - {{ off.name }} - {{ off.description }}
							</b-form-select-option>
						</b-form-select>
					</b-input-group>
				</b-form-group>
				<b-form-group
					label-for="thumbnail"
					label-cols-lg="4"
				>
					<b-form-file
						id="thumbnail"
						ref="fileimport"
						:placeholder="placeHolders.icon"
						@click.native.prevent="uploadFile"
					/>
				</b-form-group>
			</div>
		</b-modal>
		<base-block
			title="Existing Theme Categories"
			header-bg
		>
			<b-row
				class="Btn__row"
			>
				<b-col
					md="4"
					class="my-1 d-flex justify-content-between"
				>
					<b-form-group
						class="form-group"
						label-for="block-form-language"
					>
						<b-input-group>
							<b-form-select
								id="block-form-language"
								v-model="languageData"
								@change="getData()"
							>
								<b-form-select-option :value="null">
									--select language--
								</b-form-select-option>
								<b-form-select-option
									v-for="(lang,id) in language "
									:key="id"
									:value="lang.id"
								>
									{{ lang.name }}
								</b-form-select-option>
							</b-form-select>
						</b-input-group>
					</b-form-group>
					<b-form-group
						class="form-group"
						label-for="block-form-offering"
					>
						<b-input-group>
							<b-form-select
								id="block-form-offering"
								v-model="offeringData"
								@change="getData()"
							>
								<b-form-select-option :value="{}">
									--select offering--
								</b-form-select-option>
								<b-form-select-option
									v-for="(off,id) in offering "
									:key="id"
									:value="off"
								>
									{{ off.id }} - {{ off.name }} - {{ off.description }}
								</b-form-select-option>
							</b-form-select>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="d-flex flex-column justify-content-left">
				<b-col
					sm="5"
					md="9"
					class="my-1"
				>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="table.deselectRow()"
					>
						Deselect all
					</b-button>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="exportSelected()"
					>
						Export Selected
					</b-button>
					<b-button
						class="mt-3"
						@click="exportAll()"
					>
						Export all
					</b-button>
				</b-col>
			</b-row>
			<div
				ref="categoryTable"
			/>
		</base-block>
	</div>
</template>

<style scoped>
.Btn__row {
	display: flex;
	justify-content: right;
}
</style>
