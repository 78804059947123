<template>
	<div class="">
		<div class="back-arrow pl-6 pt-2">
			<router-link to="/hyperlink">
				<i class="fa fa-fw fa-arrow-left" />
				<span> Back to Hyperlinks </span>
			</router-link>
		</div>
		<base-page-heading
			title="Hyperlink Edit"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="btnShow"
					type="submit"
					size="lg"
					variant="secondary"
					@click="updateHyperlinksProperties"
				>
					Save changes
				</b-button>
			</template>
		</base-page-heading>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<div class="row content">
			<EditHyperlinksTable ref="edithyperlinks" />
		</div>
	</div>
</template>

<script lang="ts" src="./EditHyperlinks.ts"></script>
<style scoped>
.back-arrow{
	background-color: #f6f7f8;
}
</style>
